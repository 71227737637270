import { Analytics } from '@analytics';
import TopNav from '@components/Nav/TopNav';
import TipDirectLeftMenu from '@components/Nav/TopNav/TipDirectLeftMenu';
import TextIconInformationBox from '@components/Onboarding/TextIconInformationBox';
import { TextIconBoxType } from '@components/Onboarding/constants';
import { orderCardLink, StyleBreakpoints } from '@constants';
import { Button, Paper, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FilterNone as CopyIcon, GetApp as DownloadIcon } from '@material-ui/icons';
import { Alert as MuiAlert } from '@material-ui/lab';
import { formatRegionalLinks } from '@utils/helpers';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { generateQrPrintout } from './helper.js';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '10px 10px',
    gap: '40px 70px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    height: '100%',

    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      justifyContent: 'flex-start',
      marginLeft: '28px',
      padding: '0 0 50px'
    },

    [theme.breakpoints.up(StyleBreakpoints.xl)]: {
      height: '100vh'
    }
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '10px 10px',
    gap: '20px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    height: '100%',

    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      justifyContent: 'flex-start',
      marginLeft: '28px',
      padding: '0 0 50px'
    },

    [theme.breakpoints.up(StyleBreakpoints.xl)]: {
      height: '100vh'
    }
  },
  sectionBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '390px',
    borderRadius: '16px',
    backgroundColor: '#f9f9f9',
    padding: '20px 16px',
    gap: '20px'
  },
  firstColumnWrapper: {
    minWidth: '250px'
  },
  agentName: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  helperTextPaper: {
    borderRadius: '16px',
    padding: '5px 15px',
    display: 'flex'
  },
  helperTextTitle: {
    fontWeight: 'bold'
  },
  orderCardsIcon: {
    display: 'inline-block',
    margin: '0 15px 0 0',
    width: '50px'
  },
  orderCardsButton: {
    color: '#fff!important'
  },
  qrPaper: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexFlow: 'column',
    margin: ' 0',
    padding: '10px'
  },
  qrCode: {
    border: '3px solid black',
    borderRadius: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: '10px'
  },
  linkText: {
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    textTransform: 'lowercase'
  },
  tippingLinkText: {
    fontWeight: '600',
    color: '#000',
    fontSize: '12px',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  copyIcon: { cursor: 'pointer', '&:hover': { color: 'black' } },
  helperTextContainer: {
    margin: '14px 0'
  },
  tippingLink: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    paddingRight: '16px',
    gap: '16px',
    alignItems: 'center',
    borderRadius: '5.5px',
    backgroundColor: '#e7e7e7',
    padding: '12px 53px',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',

    '&:hover': {
      color: 'black'
    }
  },
  guideGalleryBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%'
  },
  downloadIcon: {
    marginLeft: '8px'
  },
  shareButton: {
    display: 'flex',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'none'
    }
  }
}));

function TipDirectLinkInformation({ user }) {
  const intl = useIntl();
  const classes = useStyles();
  const canvasRef = useRef();
  const personalCanvasRef = useRef();
  const guideGalleryQRRef = useRef();

  const [showAlert, setShouldShowCopyAlert] = useState(false);

  const linkUrl = formatRegionalLinks(`t/g/${user.userId}`);
  const guideGallerylinkUrl = formatRegionalLinks(`t/all/g/${user.companyId}`);
  const companyInviteLinkUrl = formatRegionalLinks(`signupfrominvite/${user.inviteCode}`);

  const previewTippingLink = `${linkUrl}?preview=true`;

  const handlePrintPage = () => {
    Analytics.track('download a4 printout button clicked', {
      page: 'tip direct'
    });
    generateQrPrintout(user, process.env.REGION);
  };

  function downloadQRCodeAsPNG(currentRef, filename) {
    const canvas = currentRef?.current.children[0];
    if (!canvas) {
      throw new Error('Canvas not found');
    }

    const imageData = canvas.toDataURL('image/png');
    downloadStringAsFile(imageData, filename);
  }

  function downloadStringAsFile(data, filename) {
    let a = document.createElement('a');
    a.download = filename;
    a.href = data;
    a.click();
  }

  const imageSettings = {
    height: window.innerWidth <= StyleBreakpoints.sm ? 240 : 300,
    width: window.innerWidth <= StyleBreakpoints.sm ? 240 : 300,
    x: 0,
    y: 0
  };

  const size = window.innerWidth <= StyleBreakpoints.sm ? 240 : 300;

  return (
    <>
      <TopNav />
      <div className={classes.outerContainer}>
        <TipDirectLeftMenu selectedTab={'TipDirect'} />
        <div className={classes.leftContainer}>
          <Paper className={classes.sectionBlock}>
            <Paper className={classes.agentName}>{`${user.profile.firstName} ${
              user.profile.lastName
            }`}</Paper>
            <TextIconInformationBox
              card={TextIconBoxType.PersonalQR}
              grid={true}
              noBackgroundCircle={true}
            />

            <Paper className={classes.qrPaper}>
              <div className={classes.qrCode} ref={personalCanvasRef}>
                <QRCodeCanvas value={linkUrl} size={size} imageSettings={imageSettings} />
              </div>
            </Paper>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                Analytics.track('download personal QR code clicked', {
                  page: 'tip direct'
                });
                downloadQRCodeAsPNG(personalCanvasRef, 'TripAdmit-Tap-To-Tip-QR-Code.png');
              }}>
              {intl.formatMessage(messages.downloadImage)}
              <DownloadIcon className={classes.downloadIcon} />
            </Button>
          </Paper>
        </div>

        <div className={classes.rightContainer}>
          <Paper className={classes.sectionBlock}>
            <TextIconInformationBox
              card={TextIconBoxType.PersonalTipping}
              noBackgroundCircle={true}
            />
            <div
              role="button"
              color="primary"
              className={classes.tippingLink}
              tabIndex={0}
              onKeyDown={() => {
                Analytics.track('copy tipping link', {
                  page: 'tip direct'
                });
                navigator.clipboard.writeText(linkUrl);
                setShouldShowCopyAlert(true);
              }}
              onClick={() => {
                Analytics.track('copy tipping link', {
                  page: 'tip direct'
                });
                navigator.clipboard.writeText(linkUrl);
                setShouldShowCopyAlert(true);
              }}>
              <div className={classes.tippingLinkText}>{linkUrl}</div>
              <CopyIcon className={classes.copyIcon} />
            </div>
            <Button
              className={`${classes.button} ${classes.buttonMarginTop}`}
              color="primary"
              variant="contained"
              onClick={() => {
                Analytics.track('view tipping page clicked', {
                  page: 'tip direct'
                });
                window.location.href = linkUrl;
              }}>
              {intl.formatMessage(messages.viewTippingPage)}
            </Button>
          </Paper>

          <Paper className={classes.sectionBlock}>
            <TextIconInformationBox card={TextIconBoxType.PrintOut} noBackgroundCircle={true} />
            <Button
              className={`${classes.button} ${classes.buttonMarginTop}`}
              color="primary"
              variant="contained"
              onClick={() => handlePrintPage()}>
              {intl.formatMessage(messages.downloadPrintout)}
            </Button>
          </Paper>

          <div className={classes.divider} />

          <Paper className={classes.sectionBlock}>
            <TextIconInformationBox
              card={TextIconBoxType.PreviewTippingFlow}
              noBackgroundCircle={true}
            />
            <Button
              onClick={() => {
                Analytics.track('Preview tipping flow clicked');
              }}
              variant="contained"
              color="primary"
              target="_blank"
              href={previewTippingLink}>
              {intl.formatMessage(messages.previewTippingTitle)}
            </Button>
          </Paper>

          {user.role === 'system_admin' && (
            <>
              <Paper className={classes.sectionBlock}>
                <TextIconInformationBox
                  card={TextIconBoxType.GuideGallery}
                  noBackgroundCircle={true}
                />
                <div className={classes.guideGalleryBlock}>
                  <div style={{ display: 'none' }} ref={guideGalleryQRRef}>
                    <QRCodeCanvas
                      value={guideGallerylinkUrl}
                      size={size}
                      imageSettings={imageSettings}
                    />
                  </div>
                  <div
                    role="button"
                    color="primary"
                    className={classes.tippingLink}
                    tabIndex={0}
                    onKeyDown={() => {
                      Analytics.track('copy guide gallery link', {
                        page: 'tip direct'
                      });
                      navigator.clipboard.writeText(guideGallerylinkUrl);
                      setShouldShowCopyAlert(true);
                    }}
                    onClick={() => {
                      Analytics.track('copy guide gallery link', {
                        page: 'tip direct'
                      });
                      navigator.clipboard.writeText(guideGallerylinkUrl);
                      setShouldShowCopyAlert(true);
                    }}>
                    <div className={classes.tippingLinkText}>{guideGallerylinkUrl}</div>
                    <CopyIcon className={classes.copyIcon} />
                  </div>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      Analytics.track('view Guide Gallery clicked', {
                        page: 'tip direct'
                      });
                      window.location.href = guideGallerylinkUrl;
                    }}>
                    {intl.formatMessage(messages.viewGuideGallery)}
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      Analytics.track('download guide gallery qr code clicked', {
                        page: 'tip direct'
                      });

                      downloadQRCodeAsPNG(
                        guideGalleryQRRef,
                        'TripAdmit-Tap-To-Tip-Guide-Gallery-QR-Code.png'
                      );
                    }}>
                    {intl.formatMessage(messages.downloadGalleryQR)}
                    <DownloadIcon className={classes.downloadIcon} />
                  </Button>
                </div>
              </Paper>
            </>
          )}

          <Paper className={classes.sectionBlock}>
            <TextIconInformationBox card={TextIconBoxType.OrderCards} noBackgroundCircle={true} />
            <Button
              className={classes.orderCardsButton}
              variant="contained"
              color="primary"
              target="_blank"
              href={orderCardLink}>
              {intl.formatMessage(messages.orderCards)}
            </Button>
          </Paper>

          {user.role === 'system_admin' && user.inviteCode && (
            <Paper className={classes.sectionBlock}>
              <Paper className={classes.qrPaper}>
                <div className={classes.qrCode} ref={canvasRef}>
                  <QRCodeCanvas
                    value={companyInviteLinkUrl}
                    size={size}
                    imageSettings={imageSettings}
                  />
                </div>
              </Paper>
              <TextIconInformationBox
                card={TextIconBoxType.CompanyInviteLink}
                noBackgroundCircle={true}
              />
              <div className={classes.guideGalleryBlock}>
                <Button
                  className={classes.button}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    Analytics.track('copy company invite link clicked', {
                      page: 'tip direct'
                    });
                    navigator.clipboard.writeText(companyInviteLinkUrl);
                    setShouldShowCopyAlert(true);
                  }}>
                  {intl.formatMessage(messages.copyLink)}
                  <CopyIcon className={classes.downloadIcon} />
                </Button>
                <Button
                  className={`${classes.shareButton} ${classes.button}`}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    Analytics.track('share company invite link clicked', {
                      page: 'tip direct'
                    });

                    navigator.share({
                      title: 'TripAdmit',
                      url: companyInviteLinkUrl
                    });
                  }}>
                  {intl.formatMessage(messages.share)}
                  <DownloadIcon className={classes.downloadIcon} />
                </Button>
              </div>
            </Paper>
          )}
        </div>
      </div>
      <Snackbar
        open={showAlert}
        autoHideDuration={10000}
        onClose={() => {
          setShouldShowCopyAlert(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MuiAlert
          variant="filled"
          elevation={6}
          severity="success"
          onClose={() => {
            setShouldShowCopyAlert(false);
          }}>
          {intl.formatMessage(messages.copiedToClipboard)}
        </MuiAlert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user?.user
});

export default connect(mapStateToProps)(TipDirectLinkInformation);
