import { UserRoles } from '@constants';
import { Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import { isProductEnabled } from '../../../../utils/helpers';
import Logo from '../Logo';
import NavLink from '../NavLink';
import SettingsMenu from '../SettingsMenu';
import messages from '../messages';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    marginBottom: '20px'
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    marginRight: 10
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

export function DesktopNavBar({ username, user, handleProfileMenuOpen }) {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" noWrap>
        <Logo user={user} />
      </Typography>
      {isProductEnabled('TicketingSystem', user.companyDetails.products) && (
        <>
          <NavLink aria-controls="simple-menu" aria-haspopup="true" link="/tours">
            {intl.formatMessage(messages.tours)}
          </NavLink>
          <NavLink aria-controls="simple-menu" aria-haspopup="true" link="/manifest">
            {intl.formatMessage(messages.manifest)}
          </NavLink>
          <NavLink aria-controls="simple-menu" aria-haspopup="true" link="/bookings">
            {intl.formatMessage(messages.bookings)}
          </NavLink>
          <NavLink aria-controls="simple-menu" aria-haspopup="true" link="/channel-manager">
            {intl.formatMessage(messages.channelManager)}
          </NavLink>
          <NavLink aria-controls="simple-menu" aria-haspopup="true" link="/promotions">
            {intl.formatMessage(messages.promotions)}
          </NavLink>
        </>
      )}
      {isProductEnabled('TicketingSystem', user.companyDetails.products) &&
        isProductEnabled('TipDirect', user.companyDetails.products) && (
          <NavLink aria-controls="simple-menu" aria-haspopup="true" link="/tipdirect">
            {intl.formatMessage(messages.tipdirect)}
          </NavLink>
        )}
      <div className={classes.grow} />
      <div className={classes.sectionDesktop}>
        {user.role === UserRoles.admin && (
          <NavLink aria-controls="simple-menu" aria-haspopup="true" link="/settings">
            {intl.formatMessage(messages.settings)}
          </NavLink>
        )}
        <SettingsMenu
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}>
          {username}
        </SettingsMenu>
      </div>
    </Toolbar>
  );
}

export default DesktopNavBar;
