import { Button } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { verificationSession } from '../../../api/taptotip/profile';

class VerifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { submitted: false };
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    this.setState({ stripe: await this.props.stripePromise });
  }

  async handleClick(event) {
    // Block native event handling.
    event.preventDefault();

    const { stripe } = this.state;

    if (!stripe) {
      // Stripe.js hasn't loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    // Call your backend to create the VerificationSession.
    const session = await verificationSession();

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(session.client_secret);

    if (error) {
      console.log('[error]', error.message);
    } else {
      this.setState({ submitted: true });
    }
  }

  render() {
    const { stripe, submitted } = this.state;

    if (submitted) {
      return (
        <>
          <h1>Thanks for submitting your identity document</h1>
          <p>We are processing your verification.</p>
        </>
      );
    }

    return (
      <Button
        variant="contained"
        color="secondary"
        role="link"
        disabled={!stripe}
        startIcon={<LockIcon />}
        onClick={this.handleClick}>
        Verify your identity
      </Button>
    );
  }
}

const stripePromise = loadStripe(
  'pk_test_51Gr4YqAt5pYWicpGrVErNE41qPrmHkmcJhveIXIa8cYsg0etCR9pw1uToKovZdvHkIcaVA3Ejgqsckqji7QobyHT00VHlvLHvZ'
);

//const stripePromise = loadStripe('pk_live_daEGE9atFcH2wypoqrX3mp3I00OdEoPpTk');

const App = () => {
  return <VerifyButton stripePromise={stripePromise} />;
};

export default App;
