import { Analytics } from '@analytics';
import CustomLinkIcon from '@assets/images/sub_menu/CustomLink.svg';
import SocialIcon from '@assets/images/sub_menu/Social.svg';
import ToursIcon from '@assets/images/sub_menu/Tours.svg';
import UpsellIcon from '@assets/images/sub_menu/Upsell.svg';
import LightningIcon from '@assets/images/tip_direct/LightningBolt.png';
import DashboardActive from '@assets/images/tip_direct_nav_menu/Dashboard_Active.svg';
import DashboardStatic from '@assets/images/tip_direct_nav_menu/Dashboard_Static.svg';
import PaymentsActive from '@assets/images/tip_direct_nav_menu/Payments_Active.svg';
import PaymentsStatic from '@assets/images/tip_direct_nav_menu/Payments_Static.svg';
import ProfileActive from '@assets/images/tip_direct_nav_menu/Profile_Active.svg';
import ProfileStatic from '@assets/images/tip_direct_nav_menu/Profile_Static.svg';
import ResourcesActive from '@assets/images/tip_direct_nav_menu/Resources_Active.svg';
import ResourcesStatic from '@assets/images/tip_direct_nav_menu/Resources_Static.svg';
import ReviewsActive from '@assets/images/tip_direct_nav_menu/Reviews_Active.svg';
import ReviewsStatic from '@assets/images/tip_direct_nav_menu/Reviews_Static.svg';
import SettingsActive from '@assets/images/tip_direct_nav_menu/Settings_Active.svg';
import SettingsStatic from '@assets/images/tip_direct_nav_menu/Settings_Static.svg';
import SetupActive from '@assets/images/tip_direct_nav_menu/Setup.svg';
import SetupStatic from '@assets/images/tip_direct_nav_menu/Setup_Static.svg';
import TipDirectActive from '@assets/images/tip_direct_nav_menu/TipDirect_Active.svg';
import TipDirectStatic from '@assets/images/tip_direct_nav_menu/TipDirect_Static.svg';
import UsersActive from '@assets/images/tip_direct_nav_menu/Users_Active.svg';
import UsersStatic from '@assets/images/tip_direct_nav_menu/Users_Static.svg';
import WaveIcon from '@assets/images/tip_direct_nav_menu/Wave.svg';
import ShareCompanyInviteButton from '@components/ShareCompanyInviteButton';
import InviteGuideModal from '@components/TapToTip/InviteGuideModal';
import ProPlanModal from '@components/TapToTip/ProPlanModal';
import { StyleBreakpoints, UserRoles } from '@constants';
import { AppBar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { useInjectSaga } from '../../../../utils/injectSaga';
import JumpMenu from '../JumpMenu';
import SubMenu from '../SubMenu';
import { sendRecommendToAFriend } from './actions';
import messages from './messages';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  tapToTipMenu: {
    display: 'none',
    flexDirection: 'row',
    height: '89px',
    position: 'relative',
    marginTop: '-20px',
    background: 'white',
    justifyContent: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    paddingBottom: '20px',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex',
      flexDirection: 'column',
      width: '262px',
      justifyContent: 'flex-start',
      minHeight: '100vh',
      paddingTop: '14px',
      boxShadow: 'none',
      marginLeft: '0',
      backgroundColor: '#f7f7f7',
      paddingRight: '0 !important',
      position: 'sticky',
      overflowY: 'auto'
    },

    '&::-webkit-scrollbar': {
      width: '8px',
      height: '0px',
      paddingRight: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e7e7e7',
      width: '8px',
      borderRadius: '4px'
    }
  },
  tapToTipLink: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#000000',
    background: 'white',
    border: 'none',
    borderRadius: '0',
    borderBottom: '4px solid transparent',
    margin: 'auto 15px',
    height: '32px',
    textWrap: 'nowrap',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex',
      margin: '0',
      backgroundColor: 'transparent',
      justifyContent: 'flex-start',
      height: '76.5px',
      fontSize: '14px',
      color: '#000000',
      border: 'none',
      textAlign: 'left',
      width: '100%',
      zIndex: '5'
    },
    '&.MuiButtonBase-root': {
      padding: '0'
    },
    '&:disabled': {
      color: '#000',
      opacity: '.44'
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'transparent'
    },

    '&.MuiButton-root:hover  $innerBackgroundBox': {
      display: 'flex',
      width: 'calc( 100% - 22px )',
      height: 'calc( 100% - 24px )',
      margin: '11px 12px',
      backgroundColor: '#f1f1f1'
    }
  },
  backgroundBox: {
    backgroundColor: '#f7f7f7',
    zIndex: '-2',
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  innerBackgroundBox: {
    backgroundColor: '#f7f7f7',
    zIndex: '-1',
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  activeBackgroundBox: {
    backgroundColor: '#e9e9e9',
    zIndex: '-1',
    width: 'calc( 100% - 22px )',
    height: 'calc( 100% - 24px )',
    margin: '11px 12px',
    position: 'absolute'
  },
  linkContainer: {
    position: 'relative'
  },
  activeTapToTipLink: {},
  adminLink: {
    display: 'none',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex'
    }
  },
  contentTitle: {
    fontWeight: '600',
    fontSize: '16px',
    color: '#000'
  },
  recommendButton: {
    height: '53px',
    backgroundColor: '#ffffff',
    width: 'calc( 100% - 22px )',
    margin: '11px 12px',
    borderRadius: '13px',
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: '0',
    borderImageSource: 'linear-gradient(to bottom, #00d8ff, #df3fc8)',
    borderImageSlice: '1',
    backgroundImage:
      'linear-gradient(to bottom, #fff, #fff), linear-gradient(to bottom, #00d8ff, #df3fc8)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    color: '#000000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  recommendText: {
    marginLeft: '32px',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '13px',
    textAlign: 'left'
  },
  recommendSubText: {
    fontSize: '10px',
    fontWeight: '600',
    marginTop: '-4px'
  },
  waveIcon: {
    width: '22px',
    marginLeft: '23px'
  },
  linkIcon: {
    width: '22px',
    marginLeft: '35px'
  },
  buttonText: { marginLeft: '35px' },
  upgradeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& span': {
      padding: '8px'
    }
  },
  lightningIcon: {
    width: '14px',
    marginRight: '8px'
  },
  proBadge: {
    float: 'right',
    fontSize: '10px!important',
    padding: '2px 8px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#4b4b4b',
    fontWeight: 600,
    position: 'absolute',
    right: '20px'
  }
}));

export const TapToTipTabs = {
  DASHBOARD: 'Dashboard',
  PAYMENTS: 'Payments',
  PROFILE: 'Profile',
  USERS: 'Users',
  REVIEWS: 'Reviews',
  TAPTOTIP: 'TipDirect',
  RESOURCES: 'Resources',
  SETTINGS: 'Settings',
  SETUP: 'Setup'
};

export const SubMenuTabs = {
  Setup: {
    TOURS: 'Tours',
    UPSELL: 'Upsell',
    SOCIAL: 'Social',
    CUSTOM_LINK: 'CustomLink'
  }
};

function TipDirectLeftMenu({ user, selectedTab, handleSendInvitesToGuides, exactTab }) {
  useInjectSaga({ key: 'leftMenu', saga });
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const { tab } = useParams();
  const location = useLocation();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const isFreePlanEnabled = user.companyDetails.pricingSubscription.companyPlan === 0;

  const [shownTab, setShownTab] = useState(
    tab || selectedTab || location.state?.tab || TapToTipTabs.DASHBOARD
  );
  const [isRecommendModalOpen, setIsRecommendModalOpen] = useState(false);

  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();

  useEffect(() => {
    if (location.state?.tab && shownTab !== location.state.tab) {
      setShownTab(location.state.tab);
    }
  }, [location.state, shownTab]);

  const handleTabClicked = tab => {
    setShownTab(tab);
    if (tab === TapToTipTabs.SETTINGS) {
      history.push(`/${tab.toLowerCase()}`);
    } else {
      history.push(`/tipdirect/${tab.toLowerCase()}`);
    }
  };

  const handleUpgradeClick = () => {
    setIsUpgradeModalOpen(true);
  };

  // 1. system admin
  //  1.1 free plan - show upgrade button
  //  1.2 paid plan - show recommend button
  // 2. guide
  //  2.1 free plan - show recommend button
  //  2.2 paid plan - show recommend button
  const handleShowSideButtons = () => {
    if (isFreePlanEnabled && user.role === UserRoles.admin) {
      return (
        <Button
          className={`${classes.recommendButton} ${classes.upgradeButton}`}
          onClick={handleUpgradeClick}>
          <img src={LightningIcon} className={classes.lightningIcon} alt={`Lightning Icon`} />
          {intl.formatMessage(messages.upgradeButton)}
        </Button>
      );
    }

    return (
      <>
        <ShareCompanyInviteButton companyName={user.companyName} inviteCode={user.inviteCode} />
        <Button className={classes.recommendButton} onClick={() => setIsRecommendModalOpen(true)}>
          <img src={WaveIcon} className={classes.waveIcon} alt={`Wave Icon`} />
          <div className={classes.recommendText}>
            <span>{intl.formatMessage(messages.recommend)}</span>
            <span className={classes.recommendSubText}>
              {intl.formatMessage(messages.usToAFriend)}
            </span>
          </div>
        </Button>
      </>
    );
  };

  const taptotipTabsInfo = [
    {
      name: TapToTipTabs.DASHBOARD,
      label: messages.dashboard,
      onClick: () => handleTabClicked(TapToTipTabs.DASHBOARD),
      staticIcon: DashboardStatic,
      activeIcon: DashboardActive
    },
    {
      name: TapToTipTabs.PAYMENTS,
      label: messages.payments,
      onClick: () => handleTabClicked(TapToTipTabs.PAYMENTS),
      staticIcon: PaymentsStatic,
      activeIcon: PaymentsActive
    },
    {
      name: TapToTipTabs.SETUP,
      label: messages.setup,
      onClick: () => handleTabClicked(TapToTipTabs.SETUP),
      staticIcon: SetupStatic,
      activeIcon: SetupActive
    },
    {
      name: TapToTipTabs.PROFILE,
      label: messages.profile,
      onClick: () => handleTabClicked(TapToTipTabs.PROFILE),
      staticIcon: ProfileStatic,
      activeIcon: ProfileActive
    },
    {
      name: TapToTipTabs.USERS,
      label: messages.users,
      role: UserRoles.admin,
      onClick: () => handleTabClicked(TapToTipTabs.USERS),
      staticIcon: UsersStatic,
      activeIcon: UsersActive
    },
    {
      name: TapToTipTabs.RESOURCES,
      label: messages.resources,
      role: UserRoles.admin,
      onClick: () => handleTabClicked(TapToTipTabs.RESOURCES),
      staticIcon: ResourcesStatic,
      activeIcon: ResourcesActive,
      requiredFeature: 'ResourceTippingPoints'
    },
    {
      name: TapToTipTabs.REVIEWS,
      label: messages.reviews,
      onClick: () => handleTabClicked(TapToTipTabs.REVIEWS),
      staticIcon: ReviewsStatic,
      activeIcon: ReviewsActive,
      requiredFeature: 'PostTipReviewCollection'
    },
    {
      name: TapToTipTabs.TAPTOTIP,
      label: messages.tipDirect,
      onClick: () => handleTabClicked(TapToTipTabs.TAPTOTIP),
      staticIcon: TipDirectStatic,
      activeIcon: TipDirectActive
    }
  ];

  const subMenuTabsInfo = {
    Setup: [
      {
        name: SubMenuTabs.Setup.TOURS,
        label: intl.formatMessage(messages.tours),
        icon: ToursIcon
      },
      {
        name: SubMenuTabs.Setup.UPSELL,
        label: intl.formatMessage(messages.upsell),
        icon: UpsellIcon
      },
      {
        name: SubMenuTabs.Setup.SOCIAL,
        label: intl.formatMessage(messages.social),
        icon: SocialIcon
      },
      {
        name: SubMenuTabs.Setup.CUSTOM_LINK,
        label: intl.formatMessage(messages.customLink),
        icon: CustomLinkIcon
      }
    ]
  };

  return (
    <>
      <AppBar className={classes.tapToTipMenu}>
        {taptotipTabsInfo.map(
          ({ name, label, role, onClick, staticIcon, activeIcon, requiredFeature }, i) =>
            (!role || user.role === role) && (
              <div className={classes.linkContainer} key={i}>
                <Button
                  className={`${classes.tapToTipLink} ${shownTab === name &&
                    classes.activeTapToTipLink} ${role === UserRoles.admin && classes.adminLink}`}
                  onClick={() => {
                    Analytics.track(`TTT menu clicked`, { page: name });
                    onClick();
                  }}
                  aria-controls="simple-menu"
                  aria-haspopup="true">
                  <img
                    src={shownTab === name ? activeIcon : staticIcon}
                    className={classes.linkIcon}
                    alt={`${name} Icon`}
                  />
                  <div className={classes.buttonText}>{intl.formatMessage(label)}</div>
                  <div
                    className={`${classes.innerBackgroundBox} ${shownTab === name &&
                      classes.activeBackgroundBox}`}
                  />
                  {requiredFeature && !isFeatureForCompanyEnabled(requiredFeature) && (
                    <div className={classes.proBadge}>Pro</div>
                  )}
                  <div className={classes.backgroundBox} />
                </Button>
              </div>
            )
        )}
        {handleShowSideButtons()}

        {user.role === UserRoles.admin && (
          <Button
            className={`${classes.tapToTipLink} ${shownTab === name && classes.activeTapToTipLink}`}
            onClick={() => {
              handleTabClicked(TapToTipTabs.SETTINGS);
              Analytics.track(`TTT menu clicked`, { page: TapToTipTabs.SETTINGS });
            }}
            aria-controls="simple-menu"
            aria-haspopup="true">
            <img
              src={shownTab === name ? SettingsActive : SettingsStatic}
              className={classes.linkIcon}
              alt={`Settings Icon`}
            />
            <div
              className={`${classes.innerBackgroundBox} ${shownTab === TapToTipTabs.SETTINGS &&
                classes.activeBackgroundBox}`}
            />
            <div className={classes.backgroundBox} />
            <div className={classes.buttonText}>{intl.formatMessage(messages.settings)}</div>
          </Button>
        )}

        <InviteGuideModal
          isOpen={isRecommendModalOpen}
          setIsOpen={setIsRecommendModalOpen}
          sendInvitesToGuides={handleSendInvitesToGuides}
          companyName={user.companyName}
          isRecommendationEmail
          inviteCode={user.inviteCode}
        />

        <ProPlanModal isOpen={isUpgradeModalOpen} onClose={() => setIsUpgradeModalOpen(false)} />
      </AppBar>
      <SubMenu tabsInfo={subMenuTabsInfo[shownTab]} exactTab={exactTab} />
      <JumpMenu tabsInfo={subMenuTabsInfo[shownTab]} />
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user?.user
});

const mapDispatchToProps = dispatch => ({
  handleSendInvitesToGuides: payload => dispatch(sendRecommendToAFriend(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TipDirectLeftMenu);
