/* eslint-disable no-useless-escape */
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PaymentProviders = {
  Stripe: 'stripe',
  Redeam: 'redeam',
  OnArrival: 'onArrival',
  OnAccount: 'onAccount'
};

export const StyleBreakpoints = {
  xs: '480',
  sm: '768',
  md: '992',
  lg: '1200',
  xl: '1500'
};

export const PaymentStatuses = {
  Paid: 'payment_paid',
  Unpaid: 'payment_unpaid',
  PartiallyPaid: 'payment_partially_paid',
  Refunded: 'refunded'
};

export const PaymentType = {
  stripe: 'stripe',
  onArrival: 'onArrival',
  onAccount: 'onAccount'
};

export const ChangeType = {
  BLOCK: 'block',
  UNBLOCK: 'unblock'
};

export const SignupType = {
  TicketingSystem: 'TicketingSystem',
  TapToTip: 'TapToTip'
};

export const UserRoles = {
  admin: 'system_admin',
  TapToTipAgent: 'tap_to_tip_agent'
};

export const TourStatus = {
  active: 'active',
  deleted: 'deleted'
};

export const FEES_PERCENTAGE = {
  application: 5.9,
  stripe: 2.9
};

export const TTTPaymentPlatforms = {
  stripe: 'stripe',
  revolut: 'revolut',
  wire: 'wire'
};

export const RevolutCurrencies = {
  eur: '€',
  dkk: 'DKK',
  isk: 'ISK',
  nok: 'NOK',
  pln: 'PLN',
  ron: 'RON',
  sek: 'SEK',
  gbp: 'GBP',
  usd: 'USD'
};

export const SessionStorageKeys = {
  SkipAllSteps: 'skip_all_steps'
};

export const SocialMediaRegex = {
  youtube: /^(https?:\/\/)?(www\.)?youtube\.com/i,
  instagram: /^(https?:\/\/)?(www\.)?instagram\.com/i,
  tiktok: /^(https?:\/\/)?(www\.)?tiktok\.com/i,
  facebook: /^(https?:\/\/)?(www\.)?facebook\.com/i,
  twitter: /^(https?:\/\/)?(www\.)?twitter\.com/i
};

export const CompanyPlanTextToNumber = {
  FREE0424: '0',
  PRO0424: '1',
  PROPLUS0424: '2',
  EARLYADOPTER0424: '3',
  PROLITE0424: '4'
};

export const GroupEntityType = {
  user: 'user',
  resource: 'resource',
  splitTip: 'splitTip'
};

export const regionalURLs = {
  EU: 'https://eu.tip.direct/',
  QA: 'https://qats.tripadmit.com/',
  US: 'https://us.tip.direct/'
};

export const resourceEmoji = [
  ':credit_card:',
  ':mountain_cableway:',
  ':train:',
  ':racing_motorcycle:',
  ':boat:',
  ':chair:',
  ':bike:',
  ':auto_rickshaw:',
  ':motor_scooter:',
  ':speedboat:',
  ':office:',
  ':helicopter:',
  ':camping:',
  ':circus_tent:',
  ':metro:',
  ':mountain:',
  ':ferris_wheel:',
  ':carousel_horse:',
  ':city_sunset:',
  ':bus:'
];

export const CardViewType = {
  TeamDetails: 'TeamDetails',
  TeamSettings: 'TeamSettings'
};

export const ZeroDecimalCurrencies = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF'
];

export const orderCardLink = 'https://tripadmit.com/tripadmit-tipdirect-order-cards/';
