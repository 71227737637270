import { Analytics } from '@analytics';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomSection from '../CustomSection';
import messages from './messages';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%'
  },
  textField: {
    width: '100%',
    fontSize: '14px',
    color: '#000',
    fontWeight: 500,
    backgroundColor: '#fff',

    '& .MuiInputBase-root': {
      color: '#000',
      fontWeight: 500
    }
  }
});

const initialValues = {
  thankYouTitle: '',
  thankYouText: '',
  url: '',
  promocode: ''
};

const upsellSchema = yup.object({
  thankYouTitle: yup.string().required('Title is required'),
  thankYouText: yup.string().required('Text is required'),
  url: yup
    .string()
    .matches(
      /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})(\/[^\s]*)?$/,
      'Invalid URL format'
    )
    .required('Upsell Link is required'),
  promocode: yup.string()
});

const UpsellSection = ({ upsell, updateCompanyFeatures }) => {
  const intl = useIntl();
  const classes = useStyles();

  const handleSave = values => {
    Analytics.track('Upsell Saved', {
      page: 'setup'
    });

    updateCompanyFeatures({
      upsell: values
    });
  };

  const renderSectionBody = ({ errors, touched, values, handleChange, handleBlur }) => {
    return (
      <div className={classes.container}>
        <TextField
          type="text"
          name="thankYouTitle"
          className={classes.textField}
          label={intl.formatMessage(messages.thankYouTitle)}
          variant="outlined"
          placeholder={intl.formatMessage(messages.thankYouTitle)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.thankYouTitle}
          error={touched.thankYouTitle && Boolean(errors.thankYouTitle)}
          helperText={touched.thankYouTitle && errors.thankYouTitle}
        />
        <TextField
          multiline
          rows={5}
          type="text"
          name="thankYouText"
          className={classes.textField}
          label={intl.formatMessage(messages.thankYouText)}
          variant="outlined"
          placeholder={intl.formatMessage(messages.thankYouText)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.thankYouText}
          error={touched.thankYouText && Boolean(errors.thankYouText)}
          helperText={touched.thankYouText && errors.thankYouText}
        />

        <TextField
          type="text"
          name="url"
          className={classes.textField}
          label={intl.formatMessage(messages.url)}
          variant="outlined"
          placeholder={intl.formatMessage(messages.url)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.url}
          error={touched.url && Boolean(errors.url)}
          helperText={touched.url && errors.url}
        />
        <TextField
          type="text"
          name="promocode"
          className={classes.textField}
          label={intl.formatMessage(messages.promocode)}
          variant="outlined"
          placeholder={intl.formatMessage(messages.promocode)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.promocode}
          error={touched.promocode && Boolean(errors.promocode)}
          helperText={touched.promocode && errors.promocode}
        />
      </div>
    );
  };

  return (
    <CustomSection
      id="Upsell"
      title={intl.formatMessage(messages.sectionTitle)}
      description={intl.formatMessage(messages.description)}
      renderBody={renderSectionBody}
      buttonTitle={intl.formatMessage(messages.save)}
      onSave={handleSave}
      schema={upsellSchema}
      initialValues={upsell || initialValues}
    />
  );
};

export default UpsellSection;
