/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import { Analytics } from '@analytics';
import { CircularAnimation } from '@components/CircularAnimation';
import { PrivateRoute, TSUserRoute } from '@components/Routes';
import { SignupType } from '@constants';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getDateFnsLocale } from '@utils/helpers';
import BookingWidget from 'containers/BookingWidget';
import CheckInPage from 'containers/CheckInPage';
import ManifestPage from 'containers/Manifest/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import OrderConfirmationPage from 'containers/OrderConfirmationPage';
import SignInPage from 'containers/SignIn/Loadable';
import SignUpPage from 'containers/SignUp/Loadable';
import SignUpFromInvite from 'containers/SignUpFromInvite/Loadable';
import TapToTipPage from 'containers/TapToTipPage';
import TicketPage from 'containers/TicketPage';
import Dashboard from 'containers/TipDirectPages/Dashboard';
import BookNowBar from 'containers/Widgets/BookNowBar';
import BookNowButton from 'containers/Widgets/BookNowButton';
import React, { Suspense, lazy, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import GlobalStyle from '../../global-styles';
import { getCurrentUser, setLandingUrl } from '../../store/user/actionCreators';
import ChannelManagerPage from '../ChannelManager';
import ForgotPassword from '../ForgotPassword';
import KYC from '../KYC';
import Onboarding from '../Onboarding';
import OnboardingCompletionPage from '../OnboardingCompletionPage';
import PayoutLink from '../PayoutLink';
import PayoutLinkAstraAuth from '../PayoutLink/AstraAuth';
import PayoutLinkAstraConfirm from '../PayoutLink/AstraConfirm';
import PayoutLinkPayPal from '../PayoutLink/PayPal';
import PromotionToursPage from '../PromotionToursPage';
import ResetPasswordPage from '../ResetPasswordPage';
import { ReduxConnectedStatusBar } from '../StatusBar';
import SubscriptionPostCheckout from '../SubscriptionPostCheckout';
import TapToTipAssignCard from '../TapToTipAssignCard';
import Setup from '../TipDirectPages/Setup';
import TipDirectAgentProfiles from '../TipDirectPages/TipDirectAgentProfiles';
import TipDirectLinkInformation from '../TipDirectPages/TipDirectLinkInformation';
import TipDirectReviews from '../TipDirectPages/TipDirectReviews';
import TippingPayments from '../TipDirectPages/TippingPayments';
import TippingProfile from '../TipDirectPages/TippingProfile';
import TippingResources from '../TipDirectPages/TippingResources';
import TippingGroupPage from '../TippingGroupPage';
import TourAggregate from '../Widgets/TourAggregate';
import saga from './saga';
const ResourceAssign = lazy(() => import('../ResourceAssign'));
const ResourcesEditor = lazy(() => import('../ResourcesEditor'));
const SettingsPage = lazy(() => import('containers/SettingsPage'));
const BookingPage = lazy(() => import('containers/BookingPage'));
const BookingReports = lazy(() => import('containers/BookingReports'));
const PaymentsPage = lazy(() => import('containers/Payments'));
const PromotionsPage = lazy(() => import('containers/Promotions'));
const AdditionalExtraEditor = lazy(() => import('../AdditionalExtraEditor'));
const BookingDetailsPage = lazy(() => import('../BookingDetailsPage'));
const ToursPage = lazy(() => import('containers/Tours'));
const TourEditorPage = lazy(() => import('containers/TourEditor'));

function App({ user, isUserLoading, dispatch, history }) {
  const intl = useIntl();
  useInjectSaga({ key: 'app', saga });

  useEffect(() => {
    dispatch(setLandingUrl(history.location.pathname));
    dispatch(getCurrentUser());
  }, []);

  useEffect(() => {
    if (!isUserLoading) {
      initializeAnalytics();
    }
  }, [user]);

  function initializeAnalytics() {
    Analytics.register({
      companyName: user.companyName,
      companyId: user.companyId,
      siteLanguage: user.companyLanguage,
      region: process.env.REGION
    });

    if (user && user.email) {
      Analytics.identify(user.email, user.userId);
      Analytics.addProfileInformation({ lastVisit: Date.now() });
      Analytics.addProfileInformation(
        {
          name: `${user.profile.firstName} ${user.profile.lastName}`,
          email: user.email,
          userId: user.userId,
          companyId: user.companyId,
          role: user.role,
          region: process.env.REGION,
        },
        true
      );
      Analytics.addProfileInformation(
        {
          hasMobileApp: !!user.token,
          currency: user.profile.currency,
          hasUnremittedAmount: !!user.unremittedInformation.totalUnremittedAmount
        },
        false
      );
      Analytics.loadAllExperimentsFromCache();
    }
  }

  if (isUserLoading) {
    return null; //TODO: Loader
  }

  function WaitingComponent(Component) {
    return props => (
      <Suspense fallback={CircularAnimation}>
        <Component {...props} />
      </Suspense>
    );
  }

  //TODO: Create private route component and use it
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFnsLocale(intl.locale)}>
        <Switch>
          <Route exact path="/signup">
            <SignUpPage
              type={SignupType.TapToTip}
              user={user}
              dispatch={dispatch}
              history={history}
            />
          </Route>
          <Route exact path="/signup/taptotip">
            <SignUpPage
              type={SignupType.TapToTip}
              user={user}
              dispatch={dispatch}
              history={history}
            />
          </Route>
          <Route exact path="/signup/tipdirect">
            <SignUpPage
              type={SignupType.TapToTip}
              user={user}
              dispatch={dispatch}
              history={history}
            />
          </Route>
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPasswordPage} />

          <Route exact path="/signin" component={SignInPage} />
          <Route exact path="/book" component={BookingWidget} />
          <Route exact path="/signupfrominvite/:inviteId" component={SignUpFromInvite} />
          <Route exact path="/booknow" component={BookNowButton} />
          <Route exact path="/booknowbar" component={BookNowBar} />
          <Route exact path="/tour-aggregate" component={TourAggregate} />
          <Route exact path="/bookingproto" component={WaitingComponent(BookingPage)} />
          <Route exact path="/orderconfirmation" component={OrderConfirmationPage} />
          <Route exact path="/ticket/:ticketId" component={TicketPage} />
          <Route exact path="/post-checkout" component={SubscriptionPostCheckout} />
          {/* <Route exact path="/tip/:guideId" component={TippingPage} />
          <Route exact path="/t/g/:guideId" component={TippingPage} /> */}
          {/* <Route exact path="/t/all/g/:companyId" component={TippingGuidesPage} />*/}
          {/* <Route exact path="/t/c/:cardId" component={TippingPage} />
          <Route exact path="/t/g/:guideId/r" component={TippingPage} />
          <Route exact path="/t/c/:cardId/r" component={TippingPage} /> */}

          <TSUserRoute user={user} path="/settings" component={WaitingComponent(SettingsPage)} />
          <TSUserRoute user={user} exact path="/tours" component={WaitingComponent(ToursPage)} />
          <TSUserRoute user={user} exact path="/manifest" component={ManifestPage} />
          <TSUserRoute
            user={user}
            exact
            path="/payments"
            component={WaitingComponent(PaymentsPage)}
          />
          <TSUserRoute
            user={user}
            exact
            path="/promotions"
            component={WaitingComponent(PromotionsPage)}
          />
          <TSUserRoute
            user={user}
            exact
            path="/promotions/:promotionId"
            component={PromotionToursPage}
          />
          <TSUserRoute
            user={user}
            exact
            path="/tour/:tourId/details"
            component={WaitingComponent(TourEditorPage)}
          />
          <TSUserRoute
            user={user}
            exact
            path="/bookings"
            component={WaitingComponent(BookingReports)}
          />
          <TSUserRoute
            user={user}
            exact
            path="/bookings/:bookingId"
            component={WaitingComponent(BookingDetailsPage)}
          />
          <TSUserRoute
            user={user}
            exact
            path="/bookings/:bookingId/edit"
            component={WaitingComponent(BookingDetailsPage)}
          />
          <TSUserRoute
            user={user}
            exact
            path="/additional-extra"
            component={WaitingComponent(AdditionalExtraEditor)}
          />
          <TSUserRoute
            user={user}
            exact
            path="/additional-extra/:id"
            component={WaitingComponent(AdditionalExtraEditor)}
          />
          <TSUserRoute user={user} exact path="/channel-manager" component={ChannelManagerPage} />
          <TSUserRoute user={user} exact path="/check-in/:bookingId" component={CheckInPage} />
          <TSUserRoute
            user={user}
            exact
            path="/resources"
            component={WaitingComponent(ResourcesEditor)}
          />
          <TSUserRoute
            user={user}
            exact
            path="/resources/:resourceId"
            component={WaitingComponent(ResourcesEditor)}
          />
          <TSUserRoute
            user={user}
            exact
            path="/resources/:resourceId/assign"
            component={WaitingComponent(ResourceAssign)}
          />

          <PrivateRoute user={user} exact path="/kyc" component={KYC} />
          <PrivateRoute user={user} exact path="/payoutlink" component={PayoutLink} />
          <PrivateRoute user={user} exact path="/payoutlink-confirm" component={PayoutLinkPayPal} />
          <PrivateRoute
            user={user}
            exact
            path="/payoutlink-confirm-astra"
            component={PayoutLinkAstraConfirm}
          />
          <PrivateRoute
            user={user}
            exact
            path="/payoutlink-auth-astra"
            component={PayoutLinkAstraAuth}
          />
          <PrivateRoute user={user} exact path="/taptotip" component={TapToTipPage} />
          <PrivateRoute user={user} exact path="/tipdirect" component={Dashboard} />
          <PrivateRoute user={user} exact path="/tipdirect/dashboard" component={Dashboard} />
          <PrivateRoute user={user} exact path="/tipdirect/payments" component={TippingPayments} />
          <PrivateRoute user={user} exact path="/tipdirect/profile" component={TippingProfile} />
          <PrivateRoute user={user} exact path="/tipdirect/reviews" component={TipDirectReviews} />
          <PrivateRoute user={user} exact path="/tipdirect/setup" component={Setup} />
          <PrivateRoute user={user} exact path="/tipdirect/setup/upsell" component={Setup} />
          <PrivateRoute user={user} exact path="/tipdirect/setup/social" component={Setup} />
          <PrivateRoute user={user} exact path="/tipdirect/setup/custom-link" component={Setup} />
          <PrivateRoute user={user} exact path="/tipdirect/setup/tours" component={Setup} />
          <PrivateRoute
            user={user}
            exact
            path="/tipdirect/users"
            component={TipDirectAgentProfiles}
          />
          <PrivateRoute
            user={user}
            exact
            path="/tipdirect/tipdirect"
            component={TipDirectLinkInformation}
          />
          <PrivateRoute user={user} exact path="/tipdirect" component={TapToTipPage} />
          <PrivateRoute
            user={user}
            exact
            path="/tipdirect/assign/:cardId"
            component={TapToTipAssignCard}
          />
          <PrivateRoute
            user={user}
            exact
            path="/taptotip/assign/:cardId"
            component={TapToTipAssignCard}
          />
          <PrivateRoute
            user={user}
            exact
            path="/tipdirect/resources"
            selectedTab={'resources'}
            component={TippingResources}
          />
          <PrivateRoute user={user} exact path="/group/c/:cardId" component={TippingGroupPage} />
          <PrivateRoute user={user} exact path="/group/g/:guideId" component={TippingGroupPage} />
          <PrivateRoute user={user} exact path="/taptotip/:tab" component={TapToTipPage} />
          <PrivateRoute user={user} exact path="/tipdirect/:tab" component={TapToTipPage} />
          <PrivateRoute user={user} exact path="/onboarding" component={Onboarding} />
          <PrivateRoute
            user={user}
            exact
            path="/onboarding/complete"
            component={OnboardingCompletionPage}
          />

          <Route
            exact
            path="/"
            component={({ history }) => {
              history.push('/TipDirect');
              return null;
            }}
          />
          <Route component={NotFoundPage} />
        </Switch>
        <ReduxConnectedStatusBar />
      </MuiPickersUtilsProvider>
      <GlobalStyle />
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    isUserLoading: !state.user.isInitialUserFetched
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withHistoryAndConnect = compose(
  withRouter,
  withConnect
);

export default compose(withHistoryAndConnect)(App);
