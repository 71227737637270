import TopNav from '@components/Nav/TopNav';
import TipDirectLeftMenu from '@components/Nav/TopNav/TipDirectLeftMenu';
import { StyleBreakpoints } from '@constants';
import { makeStyles } from '@material-ui/core/styles';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import * as actions from './actions';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  tapToTipMenu: {
    display: 'flex',
    flexDirection: 'row',
    height: '89px',
    position: 'relative',
    marginTop: '-20px',
    background: 'white',
    justifyContent: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex',
      flexDirection: 'column',
      width: '206px',
      justifyContent: 'flex-start',
      minHeight: '100vh',
      height: 'auto',
      paddingTop: '40px',
      boxShadow: 'none',
      marginLeft: '25px',
      borderRight: 'solid 1px #eaeaea'
    }
  },
  tapToTipLink: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#acacac',
    background: 'white',
    border: 'none',
    borderRadius: '0',
    borderBottom: '4px solid transparent',
    margin: 'auto 15px',
    height: '32px',
    textWrap: 'nowrap',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex',
      margin: '0',
      marginRight: '15px',
      justifyContent: 'left',
      fontSize: '16px',
      color: '#000000',
      opacity: '.44',
      border: 'none',
      textAlign: 'left',
      padding: '10px'
    },
    '&:disabled': {
      color: '#000',
      opacity: '.44'
    }
  },
  adminLink: {
    display: 'none',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex'
    }
  },
  activeTapToTipLink: {
    color: '#6997ff',
    borderBottom: '4px solid #6997ff',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      color: '#000000',
      opacity: '1',
      border: 'none'
    }
  },
  contentTitle: {
    fontWeight: '600',
    fontSize: '16px',
    color: '#000'
  },
  loadingSpinner: {
    margin: '50px auto'
  },
  recommendButton: {
    borderRadius: '20px',
    color: '#acacac',
    border: '2px solid #acacac',
    marginTop: '20px',
    padding: '8px 16px',
    fontSize: '12px',
    width: 'fit-content',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      display: 'none'
    }
  }
}));

export const TapToTipTabs = {
  DASHBOARD: 'Dashboard',
  PAYMENTS: 'Payments',
  PROFILE: 'Profile',
  USERS: 'Users',
  REVIEWS: 'Reviews',
  TAPTOTIP: 'TipDirect',
  TOURS: 'Tours',
  RESOURCES: 'Resources',
  SETUP: 'Setup'
};

function TapToTipPage({
  transactions,
  fetchTransactions,
  sendInvitesToGuides,
  user,
  profile,
  fetchProfile,
  updateProfile,
  isLoading,
  uploadUserProfileImage,
  connectStripeResponse,
  connectStripe,
  agentsProfiles,
  fetchAgentsProfiles,
  revokeInvite,
  activeTours,
  tours,
  fetchActiveTours,
  fetchTours,
  addToursToProfile,
  addTapToTipTour,
  editTapToTipTour,
  reviewsStatistic,
  fetchReviewsStatistic,
  multipleAssign,
  deleteTour,
  paypalLoginUrl,
  paypalAccount,
  getPayPalLoginUrl,
  paypalLoginCallback,
  match
}) {
  useInjectReducer({ key: 'taptotip', reducer });
  useInjectSaga({ key: 'taptotip', saga });
  const history = useHistory();

  const classes = useStyles();
  const intl = useIntl();
  const { tab } = useParams();
  const location = useLocation();
  // const [shownTab, setShownTab] = useState(tab || location.state?.tab || TapToTipTabs.PROFILE);
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  let urlTab = null;

  const [shownTab, setShownTab] = useState(
    urlTab || tab || location.state?.tab || TapToTipTabs.PROFILE
  );
  // const [isRecommendModalOpen, setIsRecommendModalOpen] = useState(false);
  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();
  const isReviewCollectionEnabled = isFeatureForCompanyEnabled('PostTipReviewCollection');

  return (
    <div>
      <TopNav sendInvitesToGuides={sendInvitesToGuides} />
      <div className={classes.container}>
        <TipDirectLeftMenu />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  transactions: state.taptotip?.transactions,
  profile: state.taptotip?.profile,
  isLoading: state.taptotip?.isLoading,
  connectStripeResponse: state.taptotip?.connectStripeResponse,
  agentsProfiles: state.taptotip?.agentsProfiles,
  activeTours: state.taptotip?.activeTours,
  user: state.user?.user,
  reviewsStatistic: state.taptotip?.reviewsStatistic,
  tours: state.taptotip?.tours,
  paypalLoginUrl: state.taptotip?.paypalLoginUrl,
  paypalAccount: state.taptotip?.paypalAccount
});

const mapDispatchToProps = dispatch => ({
  fetchTransactions: userId => dispatch(actions.fetchTransactions({ userId })),
  fetchProfile: userId => dispatch(actions.fetchProfile(userId)),
  updateProfile: R.compose(
    dispatch,
    actions.updateProfile
  ),
  uploadUserProfileImage: image => dispatch(actions.uploadUserProfileImage(image)),
  connectStripe: () => dispatch(actions.connectStripe()),
  fetchAgentsProfiles: () => dispatch(actions.fetchAgentsProfiles()),
  sendInvitesToGuides: payload => dispatch(actions.sendInvitesToGuides(payload)),
  fetchActiveTours: () => dispatch(actions.fetchActiveTours()),
  addToursToProfile: (userId, tourIds) => dispatch(actions.addToursToProfile({ userId, tourIds })),
  addTapToTipTour: payload => dispatch(actions.addTapToTipTour(payload)),
  editTapToTipTour: payload => dispatch(actions.editTapToTipTour(payload)),
  fetchReviewsStatistic: payload => dispatch(actions.fetchReviewsStatistic(payload)),
  revokeInvite: payload => dispatch(actions.revokeInvite(payload)),
  fetchTours: () => dispatch(actions.fetchTours()),
  multipleAssign: (tourIds, guideIds) => dispatch(actions.multipleAssign({ tourIds, guideIds })),
  deleteTour: payload => dispatch(actions.deleteTour(payload)),
  getPayPalLoginUrl: () => dispatch(actions.getPayPalLoginUrl()),
  paypalLoginCallback: code => dispatch(actions.paypalLoginCallback(code))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TapToTipPage);
