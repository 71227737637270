import { Analytics } from '@analytics';
import React from 'react';
import { useIntl } from 'react-intl';
import CustomSection from '../CustomSection';
import messages from './messages';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { SocialMediaRegex } from '@constants';
import * as yup from 'yup';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%'
  },
  textField: {
    width: '100%',
    fontSize: '14px',
    color: '#000',
    fontWeight: 500,

    '& .MuiFormHelperText-root': {
      backgroundColor: 'transparent'
    },

    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      color: '#000',
      fontWeight: 500
    }
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px'
  }
});

export const SocialLinkDetails = ({
  urlLabel,
  name,
  setFieldValue,
  handleBlur,
  values,
  errors,
  touched,
  customLabel
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const handleChange = e => {
    // if social media url is empty, set it to undefined to avoid validation error
    setFieldValue(e.target.name, e.target.value || undefined);
  };

  return (
    <div className={`${classes.inputRow} ${classes.socialInputRow}`}>
      <TextField
        type="text"
        name={`${name}.url`}
        className={classes.textField}
        label={urlLabel}
        variant="outlined"
        placeholder={intl.formatMessage(messages.url)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]?.url}
        error={touched[name]?.url && Boolean(errors[`${name}.url`])}
        helperText={touched[name]?.url && errors[`${name}.url`]}
      />
      <TextField
        type="text"
        name={`${name}.title`}
        className={classes.textField}
        label={customLabel}
        variant="outlined"
        placeholder={intl.formatMessage(messages.textFieldTitle)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]?.title}
        error={touched[name]?.title && Boolean(errors[name]?.title)}
        helperText={touched[name]?.title && errors[name]?.title}
      />
    </div>
  );
};

const initialValues = {
  thankYouTitle: '',
  thankYouText: '',
  instagram: { url: '', title: '' },
  tiktok: { url: '', title: '' },
  facebook: { url: '', title: '' },
  youtube: { url: '', title: '' },
  twitter: { url: '', title: '' }
};

const socialLinkSchema = yup.object({
  thankYouTitle: yup.string().required('Title is required'),
  thankYouText: yup.string(),
  instagram: yup.object({
    url: yup.string().matches(SocialMediaRegex.instagram, 'Wrong Instagram URL format'),
    title: yup.string()
  }),
  tiktok: yup.object({
    url: yup.string().matches(SocialMediaRegex.tiktok, 'Wrong TikTok URL format'),
    title: yup.string()
  }),
  facebook: yup.object({
    url: yup.string().matches(SocialMediaRegex.facebook, 'Wrong Facebook URL format'),
    title: yup.string()
  }),
  youtube: yup.object({
    url: yup.string().matches(SocialMediaRegex.youtube, 'Wrong YouTube URL format'),
    title: yup.string()
  }),
  twitter: yup.object({
    url: yup.string().matches(SocialMediaRegex.twitter, 'Wrong Twitter URL format'),
    title: yup.string()
  })
});

const SocialSection = ({ socialLinks, updateCompanyFeatures }) => {
  const intl = useIntl();
  const classes = useStyles();

  const socialLinkInputs = [
    { name: 'instagram', urlLabel: intl.formatMessage(messages.instagram) },
    { name: 'tiktok', urlLabel: intl.formatMessage(messages.tiktok) },
    { name: 'facebook', urlLabel: intl.formatMessage(messages.facebook) },
    { name: 'youtube', urlLabel: intl.formatMessage(messages.youtube) },
    { name: 'twitter', urlLabel: intl.formatMessage(messages.twitter) }
  ];

  const handleSave = values => {
    Analytics.track('Social Links saved', {
      page: 'setup'
    });

    updateCompanyFeatures({
      socialLinks: values
    });
  };

  const renderSectionBody = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue
  }) => (
    <div className={classes.container}>
      <TextField
        type="text"
        name="thankYouTitle"
        className={classes.textField}
        label={intl.formatMessage(messages.thankYouTitle)}
        variant="outlined"
        placeholder={intl.formatMessage(messages.thankYouTitle)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.thankYouTitle}
        error={touched?.thankYouTitle && Boolean(errors?.thankYouTitle)}
        helperText={touched?.thankYouTitle && errors?.thankYouTitle}
      />
      <TextField
        multiline
        minRows={5}
        type="text"
        name="thankYouText"
        className={classes.textField}
        label={intl.formatMessage(messages.thankYouText)}
        variant="outlined"
        placeholder={intl.formatMessage(messages.thankYouText)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.thankYouText}
        error={touched?.thankYouText && Boolean(errors?.thankYouText)}
        helperText={touched?.thankYouText && errors?.thankYouText}
      />

      {socialLinkInputs.map(({ name, urlLabel }) => (
        <SocialLinkDetails
          key={name}
          name={name}
          urlLabel={urlLabel}
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
          classes={classes}
          customLabel={intl.formatMessage(messages.socialButtonTitle)}
        />
      ))}
    </div>
  );

  return (
    <CustomSection
      id="Social"
      title={intl.formatMessage(messages.title)}
      description={intl.formatMessage(messages.description)}
      renderBody={renderSectionBody}
      buttonTitle={intl.formatMessage(messages.save)}
      onSave={handleSave}
      initialValues={socialLinks || initialValues}
      schema={socialLinkSchema}
    />
  );
};

export default SocialSection;
