import { Analytics } from '@analytics';
import StarIcon from '@assets/images/Avg_Star.svg';
import LinkIcon from '@assets/images/dashboard/LINK.svg';
import QRIcon from '@assets/images/dashboard/QR.svg';
import RFIDIcon from '@assets/images/dashboard/RFIDGrey.svg';
import AppPromotion from '@components/AppPromotion';
import TopNav from '@components/Nav/TopNav';
import TipDirectLeftMenu from '@components/Nav/TopNav/TipDirectLeftMenu';
import TextIconInformationBox from '@components/Onboarding/TextIconInformationBox';
import { TextIconBoxType } from '@components/Onboarding/constants';
import GroupCard from '@components/TapToTip/Groups/GroupCard';
import MobileConfirmationDialog from '@components/TapToTip/MobileConfirmationDialog';
import PaymentLinkQRGeneratorModal from '@components/TapToTip/PaymentLinksQRGeneratorModal';
import TotalTipsInformation from '@components/TapToTip/TotalTipsInformation';
import { StyleBreakpoints } from '@constants';
import { Avatar, Button, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert as MuiAlert } from '@material-ui/lab';
import { currencySign } from '@utils/helpers';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { RemitBalance } from '../../../api/payout';
import messages from './messages';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  contentContainer: {
    padding: '0 16px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      flex: 1,
      width: '100%'
    }
  },
  containerBackground: {
    borderRadius: '16px',
    backgroundColor: '#f9f9f9',
    padding: '24px 16px',
    width: '100%'
  },
  welcomeMessage: {
    backgroundColor: '#fff',
    padding: '20px 18px',
    alignContent: 'center',
    borderRadius: '12px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.21)'
  },
  userProfilePictureWrapper: {
    width: '70px',
    height: '70px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.29)',
    borderRadius: '50%',
    overflow: 'hidden',
    margin: '5px 0'
  },
  userProfilePicture: {
    width: '80px',
    height: '80px'
  },
  userName: {
    color: '#b0b0b0',
    fontSize: '12px'
  },
  circleButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  circleButton: {
    height: '43px',
    width: '43px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    margin: '0px 16px',

    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(96%)'
    }
  },
  recentPaymentsTable: {
    borderRadius: '14px',
    fontWeight: 'bold',
    backgroundColor: '#fff',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.23)'
  },
  paymentsHeader: {
    borderBottom: 'solid 2px #bebebe',
    height: '55px',
    padding: '20px',
    color: '#000000',
    fontSize: '18px'
  },
  paymentsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '55px',
    padding: '20px',
    color: '#000000',

    '&:nth-child(odd)': {
      backgroundColor: '#f0f0f0'
    }
  },
  paymentsButton: {
    width: '100%',
    fontSize: '14px',
    fontWeight: 600,
    marginTop: '16px',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      width: 'fit-content'
    }
  },
  ratingText: {
    color: '#000000',
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '7px'
  },
  sectionContainer: {
    padding: '17px 20px 18px 13px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.23)',
    borderRadius: '12px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    alignItems: 'center',
    height: '59px'
  },
  starIcon: {
    width: '30px'
  },
  paymentsButtonWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      justifyContent: 'flex-end'
    }
  },
  defaultAvatar: {
    width: '100%',
    height: '100%'
  },
  firstTimeDesc: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '16px',
    maxWidth: '418px',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      flex: 1,
      width: '100%',
      maxWidth: 'unset'
    }
  },
  appPromotionContainer: {
    padding: '22px 42px 22px 40px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.23)',
    borderRadius: '12px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center'
  },
  transferButton: {
    marginTop: '18px',
    width: '100%'
  },
  rfidIcon: {
    width: '43px',
    height: '43px'
  },
  topBanner: {
    backgroundColor: '#f8ed62',
    color: '#000',
    padding: '10px',
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: '16px'
  }
}));

const navigationButton = {
  tippingQRCode: {
    analyticsLabel: 'view tipping qr code',
    page: 'dashboard',
    link: '/tipdirect/tipdirect'
  },
  payments: {
    analyticsLabel: 'view payments',
    page: 'tip direct',
    link: '/tipdirect/payments'
  }
};

const Dashboard = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const [showAlert, setShouldShowCopyAlert] = useState(false);
  const [description, setDescription] = useState('');
  const [buttons, setButtons] = useState(null);
  const [isConfirmationDialogShown, setIsConfirmationDialogShown] = useState(false);
  const [dialogIcon, setDialogIcon] = useState(null);
  const [shouldShowRemiteAlert, setShouldShowRemitAlert] = useState(false);
  const [isPaymentLnkModalOpen, setIsPaymentLinkModalOpen] = useState(false);
  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();
  const [apiError, setApiError] = useState(null);

  const intl = useIntl();

  const linkUrl =
    process.env.REGION == 'EU'
      ? `https://ticketing.tripadmit.com/t/g/${user.userId}`
      : process.env.REGION == 'QA'
      ? `https://qats.tripadmit.com/t/g/${user.userId}`
      : `https://us.tip.direct/t/g/${user.userId}`;

  const handleCopyClick = () => {
    Analytics.track('copy tipping link', {
      page: 'dashboard'
    });
    navigator.clipboard.writeText(linkUrl);
    setShouldShowCopyAlert(true);
  };

  const handleNavigate = navigationDetails => {
    Analytics.track(navigationDetails.analyticsLabel, {
      page: navigationDetails.page
    });
    history.push(navigationDetails.link);
  };

  //Needs to be connected to payout service
  const handleOnDemandTransfer = async () => {
    try {
      await RemitBalance();

      setShouldShowRemitAlert(true);

      Analytics.track('transfer tips clicked', {
        page: 'dashboard'
      });
    } catch (error) {
      setApiError(error?.message);
    }
  };

  const handleKYCredirect = () => {
    Analytics.track('complete KYC clicked', {
      page: 'dashboard'
    });
    history.push('/KYC');
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogShown(false);
  };

  return (
    <>
      <TopNav />
      <div className={classes.container}>
        <TipDirectLeftMenu selectedTab={'Dashboard'} />

        <div className={classes.contentContainer}>
          {user.companyStatus === 'pending' && (
            <div className={classes.topBanner}>Account approval pending</div>
          )}
          <div className={classes.groupContainer}>
            <div className={classes.userName}>
              {user.profile.nickname || `${user.profile.firstName} ${user.profile.lastName}`}
            </div>
            <div className={classes.userProfilePictureWrapper}>
              {user.profile.avatarUrl ? (
                <img
                  className={classes.userProfilePicture}
                  src={user.profile.avatarUrl}
                  alt="Users profile"
                />
              ) : (
                <Avatar className={classes.defaultAvatar} />
              )}
            </div>
            <TotalTipsInformation
              unremittedInformation={user.unremittedInformation}
              tippingMetrics={user.tippingMetrics}
              profileCurrency={user.profile.currency}
            />
          </div>
          <div className={`${classes.groupContainer} ${classes.circleButtonWrapper}`}>
            {isFeatureForCompanyEnabled('PaymentLinks') && (
              <div
                className={classes.circleButton}
                role="button"
                onClick={() => setIsPaymentLinkModalOpen(true)}>
                <img className={classes.rfidIcon} src={RFIDIcon} alt="payment links modal" />
              </div>
            )}
            <div className={classes.circleButton} role="button" onClick={handleCopyClick}>
              <img src={LinkIcon} alt="tipping link" />
            </div>
            <div
              className={classes.circleButton}
              role="button"
              onClick={() => handleNavigate(navigationButton.tippingQRCode)}>
              <img src={QRIcon} alt="tipping qr code link" />
            </div>
          </div>
          {user?.unremittedInformation?.totalUnremittedAmount > 0 ? (
            <div className={classes.firstTimeDesc}>
              <div className={`${classes.groupContainer} ${classes.containerBackground}`}>
                <div className={classes.welcomeMessage}>
                  <FormattedMessage
                    {...messages.unclaimedTips}
                    values={{
                      unremittedAmount: `${currencySign(user.profile.currency)}${(
                        user.unremittedInformation.totalUnremittedAmount / 100
                      ).toFixed(2) || 0}`
                    }}
                  />
                </div>
                {!user.disablePayouts && (
                  <Button
                    className={classes.transferButton}
                    color="primary"
                    variant="contained"
                    onClick={() => handleOnDemandTransfer()}>
                    {intl.formatMessage(messages.transferButton)}
                  </Button>
                )}
                {user.disablePayouts && (
                  <Button
                    className={classes.transferButton}
                    color="primary"
                    variant="contained"
                    onClick={() => handleKYCredirect()}>
                    {intl.formatMessage(messages.completeKYC)}
                  </Button>
                )}
              </div>
            </div>
          ) : null}

          {user.tippingMetrics.allTime <= 0 && (
            <div className={classes.firstTimeDesc}>
              <div className={`${classes.groupContainer} ${classes.containerBackground}`}>
                <TextIconInformationBox card={TextIconBoxType.NoTipsYet} whiteBackground />
              </div>
            </div>
          )}
          {Boolean(Object.keys(user.groupDetails).length) && (
            <div className={classes.groupContainer}>
              <div className={classes.containerBackground}>
                <GroupCard
                  group={user.groupDetails}
                  user={user}
                  isUserInGroup={true}
                  fullSize={true}
                  setDescription={setDescription}
                  setButtons={setButtons}
                  setIsConfirmationDialogShown={setIsConfirmationDialogShown}
                  setDialogIcon={setDialogIcon}
                  closeConfirmationDialog={closeConfirmationDialog}
                />
              </div>
            </div>
          )}
          <div className={classes.groupContainer}>
            <div className={classes.containerBackground}>
              <div className={classes.recentPaymentsTable}>
                <div className={classes.paymentsHeader}>
                  {intl.formatMessage(messages.recentPayments)}
                </div>
                <div className={classes.paymentsRow}>
                  <div className={classes.paymentsRowHead}>
                    {intl.formatMessage(messages.lastSevenDays)}
                  </div>
                  <div className={classes.paymentsRowValue}>
                    {`${currencySign(user.profile.currency)}${(
                      user.tippingMetrics.lastSevenDays / 100
                    ).toFixed(2)}`}
                  </div>
                </div>
                <div className={classes.paymentsRow}>
                  <div className={classes.paymentsRowHead}>
                    {intl.formatMessage(messages.totalTips)}
                  </div>
                  <div className={classes.paymentsRowValue}>{user.tippingMetrics.allTimeCount}</div>
                </div>
                <div className={classes.paymentsRow}>
                  <div className={classes.paymentsRowHead}>
                    {intl.formatMessage(messages.totalAllTime)}
                  </div>
                  <div className={classes.paymentsRowValue}>{`${currencySign(
                    user.profile.currency
                  )}${(user.tippingMetrics.allTime / 100).toFixed(2)}`}</div>
                </div>
              </div>
              <div className={classes.paymentsButtonWrapper}>
                <Button
                  className={classes.paymentsButton}
                  color="primary"
                  variant="contained"
                  onClick={() => handleNavigate(navigationButton.payments)}>
                  {intl.formatMessage(messages.viewPayments)}
                </Button>
              </div>
            </div>
          </div>

          <div className={classes.groupContainer}>
            <div className={classes.containerBackground}>
              <div className={classes.appPromotionContainer}>
                <AppPromotion source={'dashboard'} />
              </div>
            </div>
          </div>
          {user.averageRatingData.averageRating !== '0' && (
            <div className={classes.groupContainer}>
              <div className={classes.containerBackground}>
                <div className={classes.sectionContainer}>
                  <img className={classes.starIcon} src={StarIcon} alt="" />
                  <div className={classes.ratingText}>
                    {`${user.averageRatingData.averageRating} ${intl.formatMessage(
                      messages.averageRating
                    )}`}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <PaymentLinkQRGeneratorModal
        isOpen={isPaymentLnkModalOpen}
        setIsOpen={setIsPaymentLinkModalOpen}
        currency={user.profile.currency}
        userId={user.userId}
        companyName={user.companyName}
      />

      <Snackbar
        open={showAlert}
        autoHideDuration={10000}
        onClose={() => {
          setShouldShowCopyAlert(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MuiAlert
          variant="filled"
          elevation={6}
          severity="success"
          onClose={() => {
            setShouldShowCopyAlert(false);
          }}>
          {intl.formatMessage(messages.copiedToClipboard)}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={shouldShowRemiteAlert}
        onClose={() => {
          setShouldShowRemitAlert(false);
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <MuiAlert
          variant="filled"
          elevation={6}
          severity="info"
          onClose={() => {
            setShouldShowRemitAlert(false);
          }}>
          {intl.formatMessage(messages.remitSuccessful)}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={Boolean(apiError)}
        onClose={() => {
          setApiError(null);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MuiAlert
          variant="filled"
          elevation={6}
          severity="error"
          onClose={() => {
            setApiError(null);
          }}>
          {apiError}
        </MuiAlert>
      </Snackbar>

      <MobileConfirmationDialog
        isShown={isConfirmationDialogShown}
        buttons={buttons}
        description={description}
        close={closeConfirmationDialog}
        icon={dialogIcon}
      />
    </>
  );
};

const mapStateToProps = state => ({
  user: state.user?.user
});

export default connect(mapStateToProps)(Dashboard);
